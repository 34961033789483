// theme.js
import { extendTheme, keyframes } from "@chakra-ui/react";

const shine = keyframes({
  "0%": { backgroundPosition: "calc(-100% - 600px) 0" },
  "100%": { backgroundPosition: "calc(100% + 600px) 0" },
});

const theme = extendTheme({
  fonts: {
    heading: "proxima-nova",
    body: "proxima-nova",
  },
  shadows: {
    default: "0 4px 0 0 rgba(231,233,237, 1)",
    focus: "0 0 0 4px rgba(188,208,246, 1)",
    hover: "0 4px 0px 0px rgba(222,232,251, 1)",
    disabled: "0 4px 0 0 rgba(91,105,135, 0.1)",
    defaultSplit:
      "-1px 0 0 0 rgba(91,105,135, 1), 0 4px 0 0 rgba(231,233,237, 1)",
    focusSplit:
      "-1px 0 0 0 rgba(0,97,235, 1), 0 0 0 4px rgba(188,208,246, 1), -1px 0 0 4px rgba(188,208,246, 1)",
    hoverSplit:
      " -1px 0 0 0 rgba(0,97,235, 1) ,0 4px 0px 0px rgba(222,232,251, 1)",
    disabledSplit:
      "-1px 0 0 0 rgba(202,207,220, 1), 0 4px 0 0 rgba(91,105,135, 0.1)",
  },
  colors: {
    gray: {
      lighterBg: "#FAFAFC",
      lightest: "#F3F5F9",
      light: "#E5E8ED",
      lighter: "#C9CFDD",
      medium: "#8390AF",
      dark: "#5B6987",
      darkest: "#031B4E",
    },
    blue: {
      lightest: "#F3F8FF",
      light: "#CEE0FA",
      medium: "#3387FF",
      dark: "#0061EB",
      darkest: "#003F99",
    },
    purple: {
      lightest: "#F7F6FE",
      light: "#9086FA",
      medium: "#6355F8",
    },
    green: {
      light: "#F4F9F6",
      medium: "#0AA653",
      dark: "#127C43",
      darkest: "#0A4726",
    },
    red: {
      lightest: "#FEF5F5",
      light: "#F77878",
      medium: "#F44848",
      dark: "#CA0C0C",
      darkest: "#870808",
    },
    orange: {
      lightest: "#FEF8F4",
      medium: "#FAA06A",
      dark: "#F56109",
    },
  },
  fontSizes: {
    sm: "14px",
    md: "16px",
    h4: "18px",
    h3: "24px",
    h2: "32px",
    h1: "48px",
  },
  global: {
    "html, body": {
      color: "gray.darkest",
      fontSize: "md",
    },
    a: {
      color: "blue.dark",
    },
  },
  space: {
    px: "1px",
    0: "0",
    0.5: "0.125rem",
    1: "0.25rem", // 4px
    1.5: "0.375rem",
    2: "0.5rem", // 8px
    2.5: "0.625rem",
    3: "0.75rem", // 12px
    3.5: "0.875rem", // 14px
    4: "1rem", // 16px
    5: "1.25rem",
    6: "1.5rem", // 24px
    7: "1.75rem",
    8: "2rem", // 32px
    9: "2.25rem",
    10: "2.5rem", // 40px
    12: "3rem", // 48px
    14: "3.5rem", // 56px
    16: "4rem", // 64px
    17: "4.5rem", // 72px
    // Additional sizing found at https://chakra-ui.com/docs/theming/theme#sizes
  },

  components: {
    Input: {
      baseStyle: {
        field: {
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "gray.medium",
          bg: "inherit",
          mb: 1,
          minHeight: 14,
          _hover: {
            borderColor: "blue.dark",
          },
          _disabled: {
            opacity: 0.6,
            cursor: "not-allowed",
          },
          _focusVisible: {
            zIndex: 1,
            borderColor: "blue.medium",
            boxShadow: "focus",
          },
        },
        variants: {
          standard: {
            borderColor: "red",
          },
        },
      },
    },
    Button: {
      variants: {
        create: {
          bg: "green.dark",
          borderRadius: "full",
          backgroundColor: "green.medium",
          color: "white",
          px: 7,
          py: 6,
        },
      },
    },
    Skeleton: {
      baseStyle: {
        borderRadius: "full",
        background: "gray.light",
        backgroundImage:
          "linear-gradient(to right, #E5E8ED 0%, #F3F5F9 50%, #E5E8ED 100%)",
        //"linear-gradient(90deg, #C9CFDD 0px, #F3F5F9 100px, #C9CFDD 80px)",
        // backgroundImage:
        //   "-webkit-gradient(linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8))",
        // backgroundImage:
        //   " -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)",
        backgroundSize: "100% 100%",
        backgroundPosition: "0 0",
        backgroundRepeat: "no-repeat",
        animation: `1.5s ease-in-out infinite ${shine}`,
        // boxShadow: "inset 0 0 0 4px white",
      },
    },
    FormLabel: {
      baseStyle: {
        fontWeight: "600",
        fontSize: "sm",
        marginBottom: "3",
      },
      sizes: {},
    },
    Small: {
      baseStyle: {
        color: "gray.dark",
        fontSize: "sm", // 14px
        lineHeight: 1,
      },
      variants: {
        muted: {
          color: "gray.medium",
        },
      },
    },
    Heading: {
      variants: {
        h1: {
          fontSize: "h1",
          fontWeight: "300",
        },
        h2: {
          fontSize: "h2",
          fontWeight: "200",
        },
        h3: {
          fontSize: "h3",
          fontWeight: "800",
        },
        h4: {
          fontSize: "h4",
          fontWeight: "600",
        },
      },
    },
    NavBar: {
      baseStyle: {
        background: "white",
        borderBottom: "1px solid",
        borderColor: "gray.lighter",
        boxShadow:
          "rgba(50, 50, 93, 0.05) 0px 6px 12px -2px, rgba(0, 0, 0, 0.15) 0px 3px 7px -3px",
        p: 5,
        mb: 10,
      },
    },
    Menu: {
      baseStyle: {
        button: {
          borderRadius: "md",
          background: "white",
          border: "1px solid",
          borderLeftWidth: "0px",
          borderColor: "gray.medium",
          boxShadow: "defaultSplit",
          _hover: {
            outline: "none",
            background: "blue.lightest",
            borderColor: "blue.dark",
            boxShadow: "hoverSplit",
          },
          _focus: {
            borderColor: "blue.dark",
            boxShadow: "hoverSplit",
            "span .chakra-icon_arrow > path": {
              fill: "blue.dark",
            },
          },
          _focusVisible: {
            borderColor: "blue.dark",
            boxShadow: "focusSplit",
            outline: "none",
            "span .chakra-icon_arrow > path": {
              fill: "blue.dark",
            },
          },
          "[data-disabled=true] ~ &": {
            borderColor: "gray.lighter",
            boxShadow: "disabledSplit",
            pointerEvents: "none",
            "span .chakra-icon_arrow > path": {
              fill: "gray.light",
            },
          },
          _expanded: {
            borderColor: "blue.dark",
            boxShadow: "focusSplit",
            "span .chakra-icon_arrow > path": {
              fill: "blue.dark",
            },
          },
          ".chakra-container.checked &": {
            boxShadow: "0 0 0 1px rgba(51,135,255, 1)",
          },
          ".chakra-container:focus-within ~ & ": {
            borderLeftColor: "transparent",
          },
        },
        list: {
          opacity: 0,
          overflowY: "auto",
          maxHeight: "0px",
          width: "auto",
          fontSize: "md",
          color: "gray.darkest",
          borderColor: "gray.lighter",
          transformOrigin: "top center",
          transition: "all 300ms ease-in-out",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          "&[data-expanded=true]": {
            opacity: 1,
            maxHeight: "300px",
          },
          "&:first-of-type > div:first-of-type .chakra-menu__group .chakra-menu__group__title":
            {
              mt: 0,
            },
          "& div > .chakra-menu__group": {
            mb: 4,
          },
          "& div:last-of-type > .chakra-menu__group": {
            mb: 0,
          },
        },
        item: {
          py: "3.5",
          _checked: {
            color: "blue.dark",
            background: "blue.lightest",
          },
        },
        groupTitle: {
          color: "gray.dark",
          position: "sticky",
          top: "-10px",
          m: "0px",
          pb: 1,
          pt: 3,
          px: 3,
          bg: "white",
        },
      },
      variants: {
        fullWidth: {
          button: {
            px: "4",
            py: "2",
            height: "14",
            width: "100%",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "gray.medium",
            boxShadow: "default",
            _hover: {
              borderColor: "blue.medium",
              boxShadow: "hover",
            },
            _focus: {
              outline: "0",
              borderColor: "blue.medium",
              boxShadow: "focus",
              background: "white",
            },
            _focusVisible: {
              outline: "0",
              borderColor: "blue.medium",
              boxShadow: "focus",
              background: "white",
            },
            _expanded: {
              borderColor: "blue.medium",
              background: "white",
              boxShadow: "focus",

              "span .chakra-icon_arrow > path": {
                fill: "blue.dark",
              },
            },
          },
        },
      },
    },
    Container: {
      variants: {
        buttonBox: {
          w: "100%",
          maxWidth: "100%",
          borderRadius: "lg",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray.medium", //Mono/DarkGrey
          boxShadow: "default",
          background: "white",
          marginBottom: 3,
          p: 0,
          //transition: "all 200ms ease-in-out",
          _hover: {
            borderColor: "blue.medium",
            boxShadow: "hover",
          },
          "&.checked": {
            borderColor: "blue.medium",
            bg: "blue.lightest",
            color: "blue.dark",
            boxShadow: "hover",
            Small: {
              color: "blue.dark",
            },
            ".chakra-menu__menu-button": {
              borderColor: "blue.dark",
            },
            ".chakra-icon > path": {
              transition: "path 200ms ease-in-out",
            },
          },
          "&[data-disabled=true]": {
            borderColor: "gray.lighter",
            pointerEvents: "none",
            boxShadow: "disabled",
          },
          _focusWithin: {
            borderColor: "blue.medium",
            boxShadow: "focus",
          },
        },
        buttonBox_split: {
          w: "100%",
          maxWidth: "100%",
          borderRadius: "6px 0 0 6px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray.medium", //Mono/DarkGrey
          borderRightWidth: "0px",
          boxShadow: "default",
          background: "white",
          marginBottom: 3,
          p: 0,
          //transition: "all 200ms ease-in-out",
          _hover: {
            borderColor: "blue.medium",
            boxShadow: "hover",
          },
          "&.checked": {
            borderColor: "blue.medium",
            bg: "blue.lightest",
            color: "blue.dark",
            boxShadow: "hover",
            Small: {
              color: "blue.dark",
            },
            ".chakra-menu__menu-button": {
              borderColor: "blue.dark",
            },
            ".chakra-icon > path": {
              transition: "path 200ms ease-in-out",
            },
          },
          "&[data-disabled=true]": {
            borderColor: "gray.lighter",
            pointerEvents: "none",
            boxShadow: "disabled",
          },
          _focusWithin: {
            zIndex: 1,
            borderColor: "blue.medium",
            boxShadow:
              "1px 0 0 0 rgba(0,97,235, 1), 0 0 0 4px rgba(188,208,246, 1), 1px 0 0 4px rgba(188,208,246, 1)",
          },
        },
        contentBox: {
          borderRadius: "md",
          p: 6,
          maxWidth: "100%",
          background: "gray.lighterBg",
          border: "1px solid",
          borderColor: "gray.light",
        },
      },
    },
    Radio: {
      // 1. We can update the base styles
      baseStyle: {
        label: {
          mx: 3,
          //color: "red",
        },
      },
      // 2. We can add a new button size or extend existing
      sizes: {},
      // 3. We can add a new visual variant
      variants: {
        buttonBox: {
          control: {
            borderColor: "gray.dark",
            borderWidth: "1px",
            bg: "white",
            transition:
              "box-shadow 400ms ease-in-out, background 400ms ease-in-out",
            _checked: {
              boxShadow: "inset 0 0 0 3px #0061EB",
              bg: "white", // override
              _before: {
                content: "none", // override
              },
              _hover: {
                bg: "white", // override
              },
            },
            _disabled: {
              bg: "gray.light",
              boxShadow: "inset 0 0 0 3px #E5E8ED",
              pointerEvents: "none",
              opacity: 1,
            },
            _hover: {
              bg: "white",
              boxShadow: "inset 0 0 0 3px #0061EB",
            },
            _focus: {
              boxShadow: "inset 0 0 0 3px #0061EB",
              _disabled: {
                boxShadow: "none",
              },
            },
            _focusVisible: {
              boxShadow: "0 0 0 3px #444",
              _disabled: {
                boxShadow: "none",
              },
            },
          },
          container: {
            w: "100%",
            py: "5",
            px: "4",
            "[data-unavailable=true] &": {
              opacity: 0.6,
            },
            _hover: {
              cursor: "pointer",
              ".chakra-radio__control": {
                borderColor: "blue.dark",
                boxShadow: "inset 0 0 0 5px #0061EB",
              },
              ".chakra-radio__control[data-checked]": {
                boxShadow: "inset 0 0 0 3px #0061EB",
              },
            },
          },
          label: {
            w: "100%",
            _disabled: {
              opacity: 1,
            },
          },
        },
        // 4. We can override existing variants
        // solid: (props) => ({
        //   bg: props.colorMode === "dark" ? "red.300" : "red.500",
        // }),
      },
    },
    Popover: {
      baseStyle: {
        overflowY: "auto",
        maxHeight: "245px",
        marginTop: "3",
        borderRadius: "lg",
        borderColor: "gray.lighter",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        py: 2,
        px: 2,
      },
    },
  },
});

export default theme;

// "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
