import {
  Box,
  BoxProps,
  ContainerProps,
  Container,
  Button,
  Flex,
  ButtonProps,
  RadioGroup,
  RadioGroupProps,
  FlexProps,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
export const MotionBox = motion<BoxProps>(Box);
export const MotionContainer = motion<ContainerProps>(Container);
export const MotionRadioGroup = motion<RadioGroupProps>(RadioGroup);
export const MotionFlex = motion<FlexProps>(Flex);
export const MotionButton = motion<ButtonProps>(Button);

export const animatedContainer = {
  hidden: {
    transition: {
      staggerChildren: 0.2,
      ease: "easeInOut",
    },
  },
  show: {
    transition: {
      staggerChildren: 0.2,
      ease: "easeInOut",
    },
  },
};

export const staggerList = {
  hidden: {
    opacity: 1,
    y: 60,
    transition: { duration: 0.4, ease: "easeInOut" },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, ease: "easeInOut" },
  },
};

export const animateInOut = {
  hidden: {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  },
};
