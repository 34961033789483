import React, { useState, useRef } from "react";
import "@reach/listbox/styles.css";
import {
  Flex,
  Radio,
  RadioGroup,
  FormLabel,
  Spacer,
  Text,
  Tooltip,
  Portal,
  Container,
} from "@chakra-ui/react";

import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  MenuList,
} from "./../modifiedDropdown/menu";
import { Skeleton } from "./../modifiedDropdown/skeleton";

import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { EngineIcon, ArrowIcon } from "./../icons";
import { MotionBox, staggerList } from "./../animated";

interface Engines {
  name: string;
  prettyName: string;
  versions: number[];
  migration: boolean;
}
const EngineSelector = (props: any) => {
  const { engines, setValue, getValues, isLoading, field, ...addtionalProps } =
    props;
  const engineRef = useRef<any>(null);

  const [dbVersion, setVersion] = useState(engines[0].versions[0]);
  const [pgVersion, setPgVersion] = useState(13);

  return (
    <MotionBox
      variants={staggerList}
      {...addtionalProps}
      layout="position"
      mb={14}
    >
      <FormLabel>
        Select a Engine
        <Tooltip
          hasArrow
          bg="gray.darkest"
          label="This resource can communicate over Private IP address only with other resources in the same VPC network."
          aria-label="VPC explanation tooltip"
          fontSize="md"
          placement="top"
        >
          <QuestionOutlineIcon ml={1} />
        </Tooltip>
      </FormLabel>
      <RadioGroup {...field}>
        {engines.map((item: Engines, idx: number) => (
          // layout="position"
          <Flex
            mb={item.versions.length > 1 ? 3 : 0}
            key={`engine-flex-container-${idx}`}
          >
            <Container
              key={item.name}
              // layout="position"
              variant={
                item.versions.length > 1 ? "buttonBox_split" : "buttonBox"
              }
              className={
                getValues("engine") === `${item.name}-${dbVersion}` &&
                !isLoading
                  ? "checked"
                  : ""
              }
              mb={item.versions.length > 1 ? 0 : 3}
              //initial={{ opacity: 0 }}
              //animate={{ opacity: 1, transition: { duration: 0.2 * idx } }}
              //exit={{ opacity: 0, transition: { duration: 0.2 * idx } }} // Doesn't work framer bug
              data-disabled={!isLoading ? false : true}
            >
              <Flex>
                <Radio
                  variant="buttonBox"
                  value={
                    item.name === "postgresql"
                      ? `${item.name}-${pgVersion}`
                      : `${item.name}-${dbVersion}`
                  }
                  isDisabled={!isLoading ? false : true}
                  onChange={(event: any) => {
                    const whichVersion =
                      item.name === "postgresql" ? pgVersion : item.versions[0];
                    setValue("engine", `${item.name}-${whichVersion}`);
                    setVersion(whichVersion);
                  }}
                  ref={item.versions.length > 1 ? engineRef : null}
                >
                  <Skeleton
                    isLoaded={!isLoading}
                    fadeDuration={0}
                    skeletonHeight="15px"
                    containerHeight="28px"
                  >
                    <Flex alignItems="center">
                      <EngineIcon
                        engine={item.name}
                        w={7}
                        h={7}
                        mr={3}
                        active={
                          getValues("engine") === `${item.name}-${dbVersion}`
                            ? true
                            : false
                        }
                      />
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        {item.prettyName}
                      </Text>
                      <Spacer />
                      {item.versions.length === 1 && (
                        <Text fontWeight="600">v{item.versions[0]}</Text>
                      )}
                    </Flex>
                  </Skeleton>
                </Radio>
              </Flex>
            </Container>
            {item.versions.length > 1 && (
              <Menu placement="bottom-end">
                <MenuButton px={4} py={2} borderRadius="0 6px 6px 0">
                  <Flex mr={2} ml={3}>
                    <Text
                      minWidth="30px"
                      fontWeight="600"
                      textAlign="left"
                      mr={6}
                      color={isLoading ? "gray.light" : "gray.darkest"}
                    >
                      v{pgVersion}
                    </Text>
                    <ArrowIcon direction="down" w={6} h={6} />
                  </Flex>
                </MenuButton>
                <Portal>
                  <MenuList>
                    <MenuOptionGroup {...field} value={pgVersion} type="radio">
                      {item.versions.map((version: any, idx: number) => (
                        <MenuItemOption
                          key={version}
                          value={version}
                          label={version}
                          onClick={(event) => {
                            setValue("engine", `${item.name}-${version}`);
                            setPgVersion(version);
                            setVersion(version);
                            engineRef?.current.focus();
                          }}
                        >
                          <Text fontWeight="600" sx={{ display: "inline" }}>
                            version: {version}
                          </Text>
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </Flex>
        ))}
      </RadioGroup>
    </MotionBox>
  );
};

export default EngineSelector;
