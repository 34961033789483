import { Global } from "@emotion/react";
import React from "react";
const Fonts = () => (
  <Global
    styles={`
      @import url("https://use.typekit.net/drv4hki.css");
      `}
  />
);
export default Fonts;
