import React, { useState, useRef } from "react";
import "@reach/listbox/styles.css";
import {
  Flex,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  Container,
  Heading,
  Portal,
} from "@chakra-ui/react";

import { Skeleton } from "../modifiedDropdown/skeleton";
import { InlineText, Small } from "../typography";
import { ArrowIcon } from "./../icons";
import { MotionContainer, MotionBox, staggerList } from "../animated";
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  MenuList,
} from "./../modifiedDropdown/menu";

const ConfigurationSelectorUseCase = (props: any) => {
  const { configs, setValue, getValues, isLoading, field, ...addtionalProps } =
    props;
  interface Configuration {
    useCase?: string;
    name: string;
    value: string;
    connections: number;
    price: number;
    machineType: string;
    type: string;
  }
  const protoConfig = configs.filter(
    (config: Configuration) => config.useCase === "prototyping",
  );
  const devConfig = configs.filter(
    (config: Configuration) => config.useCase === "development",
  );
  const advancedConfigs = configs.filter(
    (config: Configuration) =>
      config.type === "advanced" || config.type === "prod",
  );
  const basicOptions = advancedConfigs.filter(
    (config: Configuration) =>
      config.machineType !== "general-purpose" &&
      config.machineType !== "storage-optimized",
  );
  const gpOptions = advancedConfigs.filter(
    (config: Configuration) =>
      config.machineType !== "standard" &&
      config.machineType !== "storage-optimized",
  );
  const soOptions = advancedConfigs.filter(
    (config: Configuration) =>
      config.machineType !== "standard" &&
      config.machineType !== "general-purpose",
  );
  const [selectedConfig, setSelectedConfig] = useState<Configuration>({
    useCase: "production",
    name: "1 vCPU / 2 GB RAM / 25 GB SSD",
    machineType: "standard",
    value: "s-1vcpu-2gb",
    connections: 47,
    price: 30,
    type: "prod",
  });

  const configRef = useRef<any>(null);
  const menuButtonRef = useRef<any>(null);

  return (
    <MotionBox
      variants={staggerList}
      {...addtionalProps}
      layout="position"
      mb={14}
    >
      <Heading variant="h4" mb={2}>
        Choose a database configuration
      </Heading>
      <Small sx={{ display: "block" }} mb={8}>
        You will be able to change this at any time after the cluster is
        created.
      </Small>
      <RadioGroup {...field}>
        {protoConfig.map((config: Configuration, idx: number) => (
          <Container
            key={config.value}
            // layout="position"
            variant="buttonBox"
            className={
              getValues("config") === config.value && !isLoading
                ? "checked"
                : ""
            }
            data-disabled={!isLoading ? false : true}
          >
            <Flex>
              <Radio
                variant="buttonBox"
                value={config.value}
                isDisabled={!isLoading ? false : true}
              >
                <Skeleton
                  isLoaded={!isLoading}
                  fadeDuration={0}
                  skeletonHeight="15px"
                  containerHeight="42px"
                >
                  <Flex alignItems="center">
                    <Text fontWeight="600" sx={{ display: "inline" }}>
                      <InlineText textTransform="capitalize">
                        {config.useCase}
                      </InlineText>
                      <Small mt={1} fontWeight={500} sx={{ display: "block" }}>
                        {config.name} / Connection limit: {config.connections}
                      </Small>
                    </Text>
                    <Spacer />
                    <Text fontWeight="600" ml={3} textAlign="right">
                      ${config.price}/mo
                      {
                        // <Small mt={1} fontWeight={500} sx={{ display: "block" }}>
                        // ${(config.price / 730).toFixed(3)}/hr
                        // </Small>
                      }
                    </Text>
                  </Flex>
                </Skeleton>
              </Radio>
            </Flex>
          </Container>
        ))}
        {devConfig.map((config: Configuration, idx: number) => (
          <Container
            key={config.value}
            // layout="position"
            variant="buttonBox"
            className={
              getValues("config") === config.value && !isLoading
                ? "checked"
                : ""
            }
            data-disabled={!isLoading ? false : true}
          >
            <Flex>
              <Radio
                variant="buttonBox"
                value={config.value}
                isDisabled={!isLoading ? false : true}
              >
                <Skeleton
                  isLoaded={!isLoading}
                  fadeDuration={0}
                  skeletonHeight="15px"
                  containerHeight="42px"
                >
                  <Flex alignItems="center">
                    <Text fontWeight="600" sx={{ display: "inline" }}>
                      <InlineText textTransform="capitalize">
                        {config.useCase}
                      </InlineText>
                      <Small mt={1} fontWeight={500} sx={{ display: "block" }}>
                        {config.name} / Connection limit: {config.connections}
                      </Small>
                    </Text>
                    <Spacer />
                    <Text fontWeight="600" textAlign="right" ml={3}>
                      ${config.price}/mo
                      {
                        // <Small mt={1} fontWeight={500} sx={{ display: "block" }}>
                        // ${(config.price / 730).toFixed(3)}/hr
                        // </Small>
                      }
                    </Text>
                  </Flex>
                </Skeleton>
              </Radio>
            </Flex>
          </Container>
        ))}
        <Flex>
          <MotionContainer
            variant="buttonBox_split"
            data-disabled={!isLoading ? false : true}
            className={
              getValues("config") === selectedConfig.value && !isLoading
                ? "checked"
                : ""
            }
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (!selectedConfig.value) {
                event.preventDefault();
                menuButtonRef.current.click();
              }
            }}
          >
            <Flex>
              <Radio
                variant="buttonBox"
                value={selectedConfig.value}
                ref={configRef}
                isDisabled={isLoading ? true : false}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (!selectedConfig.value) {
                    event.preventDefault();
                    menuButtonRef.current.click();
                  }
                }}
              >
                <Skeleton
                  isLoaded={!isLoading}
                  skeletonHeight="15px"
                  containerHeight="42px"
                  fadeDuration={0}
                >
                  <Flex alignItems="center">
                    <Flex alignItems="center">
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        <InlineText textTransform="capitalize">
                          {selectedConfig.useCase}
                        </InlineText>
                        <Small
                          mt={1}
                          fontWeight={500}
                          sx={{ display: "block" }}
                        >
                          {selectedConfig.name} / Connection limit:{" "}
                          {selectedConfig.connections}
                        </Small>
                      </Text>
                    </Flex>
                    <Spacer />
                    <Text fontWeight="600" textAlign="right" ml={3}>
                      ${selectedConfig.price}/mo
                    </Text>
                  </Flex>
                </Skeleton>
              </Radio>
            </Flex>
          </MotionContainer>
          <Menu placement="bottom-end">
            <MenuButton
              px={4}
              py={2}
              mb={3}
              ref={menuButtonRef}
              borderRadius="0 6px 6px 0"
            >
              <ArrowIcon direction="down" w={6} h={6} />
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuOptionGroup
                  {...field}
                  title="Basic nodes"
                  marginInlineStart={3}
                  value={selectedConfig.value}
                  type="radio"
                >
                  {basicOptions.map((item: any) => (
                    <MenuItemOption
                      key={item.value}
                      value={item.value}
                      label={item.location}
                      isDisabled={item.disabled}
                      onClick={() => {
                        setSelectedConfig(item);
                        setValue("config", item.value);
                      }}
                    >
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        ${item.price}/mo
                      </Text>
                      <Text
                        fontWeight="400"
                        sx={{
                          display: "inline",
                        }}
                      >
                        &nbsp;- {item.name}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
                <MenuOptionGroup
                  {...field}
                  title="General purpose nodes"
                  mt={5}
                  marginInlineStart={3}
                  value={selectedConfig.value}
                  type="radio"
                >
                  {gpOptions.map((item: any) => (
                    <MenuItemOption
                      key={item.value}
                      value={item.value}
                      label={item.location}
                      isDisabled={item.disabled}
                      onClick={() => {
                        setSelectedConfig(item);
                        setValue("config", item.value);
                      }}
                    >
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        ${item.price}/mo
                      </Text>
                      <Text
                        fontWeight="400"
                        sx={{
                          display: "inline",
                        }}
                      >
                        &nbsp;- {item.name}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
                <MenuOptionGroup
                  {...field}
                  title="Storage Optimized nodes"
                  mt={5}
                  marginInlineStart={3}
                  value={selectedConfig.value}
                  type="radio"
                >
                  {soOptions.map((item: any) => (
                    <MenuItemOption
                      key={item.value}
                      value={item.value}
                      label={item.location}
                      isDisabled={item.disabled}
                      onClick={() => {
                        setSelectedConfig(item);
                        setValue("config", item.value);
                      }}
                    >
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        ${item.price}/mo
                      </Text>
                      <Text
                        fontWeight="400"
                        sx={{
                          display: "inline",
                        }}
                      >
                        &nbsp;- {item.name}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      </RadioGroup>
    </MotionBox>
  );
};

export default ConfigurationSelectorUseCase;
