import React, { useState, useEffect } from "react";
import "@reach/listbox/styles.css";
import {
  Flex,
  FormLabel,
  Spacer,
  Text,
  Heading,
  Container,
  Input,
  Portal,
} from "@chakra-ui/react";
import { Small, InlineText } from "./../typography";
import { MotionBox, staggerList } from "./../animated";
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  MenuList,
} from "./../modifiedDropdown/menu";
import { ProjectIcon, ArrowIcon } from "./../icons";

interface Engines {
  name: string;
  prettyName: string;
  versions: number[];
  migration: boolean;
}
const FinalizeAndCreate = (props: any) => {
  const {
    Controller,
    control,
    dirtyFields,
    formUpdates,
    setValue,
    getValues,
    options,
    isLoading,
    ...addtionalProps
  } = props;

  const [startTime] = useState(Math.floor(Date.now() / 1000));
  const [getConfig, setConfig] = useState({
    useCase: "production",
    name: "1 vCPU / 2 GB RAM / 25 GB SSD",
    machineType: "standard",
    value: "s-1vcpu-2gb",
    connections: 47,
    price: 30,
    type: "prod",
  });

  const [getProject, setProject] = useState(options.projects[0]);
  useEffect(() => {
    if (!dirtyFields.resourceName)
      setValue(
        "resourceName",
        `db-${getValues("engine")}-${getValues("region")
          .toString()
          .toLowerCase()}-${startTime}`,
      );
    const idx = options.configuration.findIndex(
      (config: any) => config.value === formUpdates[2],
    );

    setConfig(options.configuration[idx]);
  }, [formUpdates]);

  return (
    <MotionBox layout="position" variants={staggerList} mb={6}>
      <Heading variant="h4" mb={6}>
        Finalize and Create
      </Heading>
      <FormLabel htmlFor="resourceName" mb={2} mt={4}>
        Resource name
      </FormLabel>
      <Controller
        name="resourceName"
        control={control}
        render={({ field }: any) => (
          <React.Fragment>
            <Input variant={"standard"} autoComplete="off" {...field} />
            <Small>
              Space names must be unique and contain alphanumeric characters,
              dashes, and periods only.
            </Small>
          </React.Fragment>
        )}
      />
      <FormLabel htmlFor="resourceName" mb={2} mt={8}>
        Select a project
      </FormLabel>
      <Controller
        name="project"
        control={control}
        render={({ field }: any) => (
          <Menu
            placement="bottom-start"
            matchWidth
            gutter={0}
            variant={"fullWidth"}
          >
            <MenuButton>
              <Flex>
                <ProjectIcon project={getProject.avatar} w={6} h={6} mr={3} />
                <Text fontWeight="600">{getProject.name}</Text>
                <Spacer />
                <ArrowIcon direction="down" w={6} h={6} />
              </Flex>
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuOptionGroup
                  {...field}
                  marginInlineStart={3}
                  type="radio"
                  value={getValues("project")}
                  onChange={(value) => {
                    const idx = options.projects.findIndex(
                      (item: any) => item.name === value,
                    );
                    setProject(options.projects[idx]);
                    setValue("project", value);
                  }}
                >
                  {options.projects.map((item: any) => (
                    <MenuItemOption
                      key={item.name}
                      value={item.name}
                      label={item.name}
                    >
                      <ProjectIcon project={item.avatar} w={4} h={4} mr={3} />
                      <Text
                        fontWeight="600"
                        sx={{
                          display: "inline",
                        }}
                      >
                        {item.name}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          </Menu>
        )}
      />
      <FormLabel mt={10}>Final cost and configuration</FormLabel>
      <Container variant="contentBox" mb={14}>
        <Flex>
          <Text fontWeight="600">
            <InlineText textTransform="capitalize">
              {getConfig.useCase}
            </InlineText>
            <Small mt={2} fontWeight="400" sx={{ display: "block" }}>
              {getConfig.name} / {getConfig.connections} Connection limit
            </Small>
          </Text>
          <Spacer />
          <Text fontWeight="600">
            ${getConfig.price}/mo
            <Small mt={2} fontWeight="400" sx={{ display: "block" }}>
              ${(getConfig.price / 730).toFixed(3)}/hr
            </Small>
          </Text>
        </Flex>
      </Container>
    </MotionBox>
  );
};

export default FinalizeAndCreate;
