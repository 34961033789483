import { useStyleConfig } from "@chakra-ui/system";
import { BoxProps, Heading, Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { MotionBox } from "./../animated";
import { Small } from "./../typography";
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  MenuList,
} from "./../modifiedDropdown/menu";
interface NavBarProps extends BoxProps {
  variant?: string;
  createOptions?: any;
  onChange?: any;
  isLoading?: boolean;
  getValues?: any;
  setLoading?: any;
}
interface Region {
  id: number;
  location: string;
  datacenter: number;
  value: string;
  country: string;
  resources?: number;
  nearest?: boolean;
  disabled?: Boolean;
}
interface VPC {
  name: string;
  resources: number;
  default: boolean;
}
interface Engines {
  name: string;
  prettyName: string;
  versions: number[];
  migration: boolean;
}
interface Configuration {
  useCase?: string;
  name: string;
  value: string;
  connections: number;
  price: number;
  machineType: string;
  type: string;
}
interface Projects {
  name: string;
  avatar: string;
}
interface OptionsForCreate {
  regions: Region[];
  vpcs: VPC[];
  engines: Engines[];
  configuration: Configuration[];
  projects: Projects[];
}
const allRegions: Region[] = [
  {
    id: 1,
    location: "New York",
    datacenter: 1,
    value: "NYC1",
    country: "USA",
    nearest: true,
  },
  {
    id: 2,
    location: "New York",
    datacenter: 2,
    value: "NYC2",
    country: "USA",
    nearest: true,
  },
  {
    id: 3,
    location: "New York",
    datacenter: 3,
    value: "NYC3",
    country: "USA",
    nearest: true,
  },
  {
    id: 4,
    location: "San Francisco",
    datacenter: 1,
    value: "SFO1",
    country: "USA",
  },
  {
    id: 5,
    location: "San Francisco",
    datacenter: 2,
    value: "SFO2",
    country: "USA",
  },
  {
    id: 6,
    location: "San Francisco",
    datacenter: 3,
    value: "SFO3",
    country: "USA",
  },
  {
    id: 7,
    location: "Amsterdam",
    datacenter: 2,
    value: "AMS2",
    country: "AMS",
  },
  { id: 8, location: "London", datacenter: 1, value: "LON1", country: "GBR" },

  {
    id: 9,
    location: "Amsterdam",
    datacenter: 3,
    value: "AMS3",
    country: "AMS",
    disabled: true,
  },
  {
    id: 10,
    location: "Singapore",
    datacenter: 1,
    value: "SPG1",
    country: "SPO",
  },
  {
    id: 11,
    location: "Frankfurt",
    datacenter: 1,
    value: "FRA1",
    country: "GER",
  },
  {
    id: 12,
    location: "Toronto",
    datacenter: 1,
    value: "TOR1",
    country: "CAN",
    disabled: true,
  },
  {
    id: 13,
    location: "Bangalore",
    datacenter: 1,
    value: "BLR1",
    country: "IND",
  },
];
export const defaultCreateOptions: OptionsForCreate = {
  regions: allRegions,
  vpcs: [
    {
      name: "default",
      resources: 12,
      default: true,
    },
  ],
  engines: [
    {
      name: "mongodb",
      prettyName: "MongoDB",
      versions: [4],
      migration: false,
    },
    {
      name: "postgresql",
      prettyName: "PostgreSQL",
      versions: [10, 11, 12, 13],
      migration: true,
    },
    {
      name: "mysql",
      prettyName: "MySQL",
      versions: [8],
      migration: true,
    },
    {
      name: "redis",
      prettyName: "Redis",
      versions: [6],
      migration: true,
    },
  ],
  configuration: [
    {
      useCase: "prototyping",
      name: "1 vCPU / 512 MB RAM / 1 GB SSD",
      machineType: "standard",
      value: "s-1vcpu-512mb",
      connections: 10,
      price: 7,
      type: "dev",
    },
    {
      useCase: "development",
      name: "1 vCPU / 1 GB RAM / 10 GB SSD",
      machineType: "standard",
      value: "s-1vcpu-1gb",
      connections: 22,
      price: 15,
      type: "dev",
    },

    {
      useCase: "production",
      name: "1 vCPU / 2 GB RAM / 25 GB SSD",
      machineType: "standard",
      value: "s-1vcpu-2gb",
      connections: 47,
      price: 30,
      type: "prod",
    },
    {
      useCase: "production",
      name: "2 vCPU / 4 GB RAM / 38 GB SSD",
      machineType: "standard",
      value: "s-2vcpu-4gb",
      connections: 97,
      price: 60,
      type: "prod",
    },
    {
      useCase: "production",
      name: "4 vCPU / 8 GB RAM / 115 GB SSD",
      machineType: "standard",
      value: "s-4vcpu-8gb",
      connections: 197,
      price: 120,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "6 vCPU / 16 GB RAM / 270 GB SSD",
      machineType: "standard",
      value: "s-6vcpu-16gb",
      connections: 397,
      price: 240,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "8 vCPU / 32 GB RAM / 580 GB SSD",
      machineType: "standard",
      value: "s-8vcpu-32gb",
      connections: 797,
      price: 480,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "16 vCPU / 64 GB RAM / 1012 GB SSD",
      machineType: "standard",
      value: "s-16vcpu-64gb",
      connections: 1597,
      price: 960,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "2 vCPU / 8 GB RAM / 25 GB SSD",
      machineType: "general-purpose",
      value: "gp-2vcpu-8gb",
      connections: 197,
      price: 115,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "4 vCPU / 16 GB RAM / 60 GB SSD",
      machineType: "general-purpose",
      value: "gp-4vcpu-16gb",
      connections: 397,
      price: 230,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "8 vCPU / 32 GB RAM / 145 GB SSD",
      machineType: "general-purpose",
      value: "gp-8vcpu-32gb",
      connections: 797,
      price: 450,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "16 vCPU / 64 GB RAM / 325 GB SSD",
      machineType: "general-purpose",
      value: "gp-16vcpu-64gb",
      connections: 1597,
      price: 900,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "32 vCPU / 128 GB RAM / 695 GB SSD",
      machineType: "general-purpose",
      value: "gp-32vcpu-128gb",
      connections: 3197,
      price: 1820,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "40 vCPU / 160 GB RAM / 875 GB SSD",
      machineType: "general-purpose",
      value: "gp-40vcpu-160gb",
      connections: 3997,
      price: 2275,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "2 vCPU / 16 GB RAM / 400 GB NVMe",
      machineType: "storage-optimized",
      value: "gp-2vcpu-16gb",
      connections: 397,
      price: 240,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "4 vCPU / 32 GB RAM / 845 GB NVMe",
      machineType: "storage-optimized",
      value: "gp-4vcpu-32gb",
      connections: 797,
      price: 480,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "8 vCPU / 64 GB RAM / 1.68 TB NVMe",
      machineType: "storage-optimized",
      value: "gp-8vcpu-64gb",
      connections: 1597,
      price: 960,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "16 vCPU / 128 GB RAM / 3.41 TB NVMe",
      machineType: "storage-optimized",
      value: "gp-16vcpu-128gb",
      connections: 3197,
      price: 1920,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "24 vCPU / 192 GB RAM / 5.14 TB NVMe",
      machineType: "storage-optimized",
      value: "gp-24vcpu-192gb",
      connections: 4797,
      price: 2880,
      type: "advanced",
    },
    {
      useCase: "production",
      name: "32 vCPU / 256 GB RAM / 6.86 TB NVMe",
      machineType: "storage-optimized",
      value: "gp-32vcpu-256gb",
      connections: 6397,
      price: 3830,
      type: "advanced",
    },
  ],
  projects: [
    {
      name: "Sofishticated",
      avatar: "A",
    },
    {
      name: "Yeah, bouy",
      avatar: "B",
    },
    {
      name: "Shellfish choices",
      avatar: "C",
    },
  ],
};
const scenarios: any = {
  userBase: 546892,
  regions: [
    {
      label: "No resources",
      regionCount: 0,
      resourceLocations: [],
      userCount: "New customer",
    },
    {
      label: "Resources in one datacenter region",
      regionCount: 1,
      resourceLocations: [{ value: "NYC1", resources: 8 }],
      userCount: "82.8% of customers",
    },
    {
      label: "Resources in two datacenter regions",
      regionCount: 2,
      resourceLocations: [
        { value: "NYC1", resources: 8 },
        { value: "SFO3", resources: 3 },
      ],
      resourceCounts: 8,
      userCount: "12.5% of customers",
    },
    {
      label: "Resources in three datacenter regions",
      regionCount: 3,
      resourceLocations: [
        { value: "NYC1", resources: 8 },
        { value: "SFO3", resources: 3 },
        { value: "LON1", resources: 7 },
      ],
      resourceCounts: 8,
      userCount: "3.1% of customers",
    },
    {
      label: "Resources in four datacenter regions",
      regionCount: 4,
      resourceLocations: [
        { value: "NYC1", resources: 8 },
        { value: "SFO3", resources: 3 },
        { value: "LON1", resources: 7 },
        { value: "AMS3", resources: 3 },
      ],
      resourceCounts: 8,
      userCount: "0.9% of customers",
    },
    {
      label: "Resources in five datacenter regions",
      regionCount: 5,
      resourceLocations: [
        { value: "NYC1", resources: 8 },
        { value: "SFO3", resources: 3 },
        { value: "LON1", resources: 7 },
        { value: "AMS3", resources: 3 },
        { value: "SPG1", resources: 1 },
      ],
      userCount: "0.4% of customers",
    },
  ],
  vpcs: [
    {
      label: "Default only",
      customVPC: 0,
      userCount: "97.85% of total VPC usage",
    },
    {
      label: "One custom VPC",
      customVPC: 1,
      userCount: "1.1% of total VPC usage",
    },
    {
      label: "Two custom VPCs",
      customVPC: 2,
      userCount: "0.72% of total VPC usage",
    },
    {
      label: "Three custom VPCs",
      customVPC: 3,
      userCount: "0.31% of total VPC usage",
    },
    {
      label: "Four custom VPCs",
      customVPC: 4,
      userCount: "0.016% of total VPC usage",
    },
  ],
  resourceName: "",
  password: "",
};

export const NavBar: React.FC<NavBarProps> = (props) => {
  const {
    getValues,
    setLoading,
    isLoading,
    createOptions,
    variant,
    children,
    ...rest
  } = props;

  const styles = useStyleConfig("NavBar", { variant });

  const [formOptions, updateFormOptions] = useState({
    regionConfig: "No resources",
    vpcConfig: "Default",
    loadingSpeed: "1 second",
  });

  const createOptionsChange = (value: any) => {
    props.onChange(value);
  };

  const updateSettings = (field: string, idx: number, value: string) => {
    const newCreateOptions = { ...createOptions };
    const newFormOption = { ...formOptions };

    if (field === "regions") {
      const newRegions = [...allRegions];
      const checkResources = scenarios.regions[idx].resourceLocations;
      let merged = [];
      for (let i = 0; i < newRegions.length; i++) {
        merged.push({
          ...newRegions[i],
          ...checkResources.find(
            (itmInner: any) => itmInner.value === newRegions[i].value,
          ),
        });
      }

      newCreateOptions.regions = merged;
      newCreateOptions.vpcs[0].name = `default`;
      newFormOption.regionConfig = value;
    }
    if (field === "vpcs") {
      const vpcArr = [
        {
          name: `default`,
          resources: 12,
          default: true,
        },
      ];
      const length = scenarios.vpcs[idx].customVPC;
      for (let i = 0; i < length; i++) {
        const newVPC: VPC = {
          name: `custom-vpc-${i}`,
          resources: Math.floor(Math.random() * 20),
          default: false,
        };
        vpcArr.push(newVPC);
      }
      newCreateOptions.vpcs = vpcArr;
      newFormOption.vpcConfig = value;
    }

    createOptionsChange(newCreateOptions);
    updateFormOptions(newFormOption);
  };
  return (
    <Box __css={styles} role="navigation" aria-label="Main" {...rest}>
      <Flex alignItems="center" justifyContent="flex-start">
        <Heading variant="h4" mr="3">
          Use Cases:
        </Heading>
        <MotionBox mr={10}>
          <Menu placement="bottom-start">
            <MenuButton px={4} py={2} mr={3} maxWidth="230px">
              <Flex alignItems="center">
                <Text
                  textAlign="left"
                  mr={3}
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Small sx={{ fontWeight: "800", display: "block" }}>
                    Region
                  </Small>
                  {formOptions.regionConfig}
                </Text>
                <ChevronDownIcon w={6} h={6} />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                onChange={(value: any) => {
                  const idx = scenarios.regions.findIndex(
                    (item: any) => item.label === value,
                  );
                  updateSettings("regions", idx, value);
                }}
                Type="radio"
              >
                {scenarios.regions.map((item: any) => (
                  <MenuItemOption
                    key={item.regionCount}
                    value={item.label}
                    label={item.label}
                  >
                    <Text fontWeight="600" sx={{ display: "inline" }}>
                      {item.label}
                      <Small sx={{ display: "block" }}>{item.userCount}</Small>
                    </Text>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <Menu placement="bottom-start">
            <MenuButton px={4} py={2} mr={3}>
              <Flex alignItems="center">
                <Text textAlign="left" mr={3}>
                  <Small sx={{ fontWeight: "800", display: "block" }}>
                    VPC
                  </Small>
                  {formOptions.vpcConfig}
                </Text>
                <ChevronDownIcon w={6} h={6} />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                onChange={(value: any) => {
                  const idx = scenarios.vpcs.findIndex(
                    (item: any) => item.label === value,
                  );
                  updateSettings("vpcs", idx, value);
                }}
                Type="radio"
              >
                {scenarios.vpcs.map((item: any) => (
                  <MenuItemOption
                    key={item.label}
                    value={item.label}
                    label={item.label}
                  >
                    <Text fontWeight="600" sx={{ display: "inline" }}>
                      {item.label}
                      <Small sx={{ display: "block" }}>{item.userCount}</Small>
                    </Text>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </MotionBox>
        <Heading variant="h4" mr="3">
          Utilities:
        </Heading>
        <MotionBox>
          <Menu placement="bottom-start">
            <MenuButton px={4} py={2}>
              <Flex alignItems="center">
                <Text textAlign="left" mr={3}>
                  <Small sx={{ fontWeight: "800", display: "block" }}>
                    Loading speed
                  </Small>
                  {formOptions.loadingSpeed}
                </Text>
                <ChevronDownIcon w={6} h={6} />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                onChange={(value: any) => {
                  const newSpeed = parseFloat(value);
                  formOptions.loadingSpeed =
                    newSpeed <= 1000
                      ? `${newSpeed / 1000} second`
                      : `${newSpeed / 1000} seconds`;
                  setLoading(newSpeed);
                }}
                Type="radio"
              >
                <MenuItemOption value="1000" label="Fastest">
                  <Text fontWeight="600" sx={{ display: "inline" }}>
                    Fastest - High speed internet
                  </Text>
                </MenuItemOption>
                <MenuItemOption value="2000" label="Fast">
                  <Text fontWeight="600" sx={{ display: "inline" }}>
                    Fast - 5G
                  </Text>
                </MenuItemOption>
                <MenuItemOption value="4000" label="Slow">
                  <Text fontWeight="600" sx={{ display: "inline" }}>
                    Slow - 3G
                  </Text>
                </MenuItemOption>
                <MenuItemOption value="10000" label="Testing - 10">
                  <Text fontWeight="600" sx={{ display: "inline" }}>
                    Testing - 10 seconds
                  </Text>
                </MenuItemOption>
                <MenuItemOption value="100000" label="Testing - 100">
                  <Text fontWeight="600" sx={{ display: "inline" }}>
                    Testing - 100 seconds
                  </Text>
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </MotionBox>
      </Flex>
    </Box>
  );
};
