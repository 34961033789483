import React, { useState, useEffect } from "react";
import "@reach/listbox/styles.css";
import {
  Flex,
  Radio,
  RadioGroup,
  FormLabel,
  Spacer,
  Text,
  Badge,
  Tooltip,
  Link,
  Spinner,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { QuestionOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";

import { Small } from "./../typography";
import { MotionBox, staggerList, MotionContainer } from "./../animated";
import { Skeleton } from "./../modifiedDropdown/skeleton";

interface Vpc {
  location: string;
  value: string;
  default: boolean;
  country: string;
  datacenter: number;
  resources?: number;
  name?: string;
}

const getResources = (regions: any, value: any) => {
  const resources = regions.filter(
    (region: any) =>
      region.value === value && region.hasOwnProperty("resources"),
  );
  if (!resources.length) return "";

  return `${resources[0].resources} resources`;
};

const VpcSelector = (props: any) => {
  const { options, setValue, getValues, isLoading, field, ...addtionalProps } =
    props;

  const currentRegion = getValues("region").toString();
  const [vpcIsLoading, setVpcLoading] = useState(true);

  useEffect(() => {
    setVpcLoading(true);
    setValue("vpc", `default-${getValues("region").toLowerCase()}`);

    const timer = setTimeout(() => {
      setVpcLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [getValues, options, setValue]);

  useEffect(() => {
    setVpcLoading(true);
    setValue("vpc", `default-${currentRegion.toLowerCase()}`);

    const timer = setTimeout(() => {
      setVpcLoading(false);
    }, 500);

    console.log(options.vpcs);

    return () => clearTimeout(timer);
  }, [currentRegion, options, setValue]);

  return (
    <MotionBox
      key="vpc"
      variants={staggerList}
      {...addtionalProps}
      layout="position"
      mb={14}
    >
      <AnimatePresence>
        {options.vpcs.length > 1 ? (
          <MotionBox key="vpc-fragment-wrapper" mt={14}>
            <FormLabel>
              Select a VPC network{" "}
              <Tooltip
                hasArrow
                bg="gray.darkest"
                label="This resource can communicate over Private IP address only with other resources in the same VPC network."
                aria-label="VPC explanation tooltip"
                fontSize="md"
                placement="top"
              >
                <QuestionOutlineIcon ml={1} />
              </Tooltip>
            </FormLabel>
            <RadioGroup {...field} mb={5}>
              <AnimatePresence>
                {options.vpcs.map((item: Vpc, idx: number) => (
                  <MotionContainer
                    key={item.name}
                    variant="buttonBox"
                    className={
                      getValues("vpc") ===
                        `${item.name}-${currentRegion.toLowerCase()}` &&
                      !vpcIsLoading &&
                      !isLoading
                        ? "checked"
                        : ""
                    }
                    variants={{
                      hidden: {
                        opacity: 0,
                        y: 0,
                        transition: {
                          duration: 0.1 * idx,
                          ease: "easeInOut",
                        },
                      },
                      show: {
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: 0.4 * idx,
                          ease: "easeInOut",
                        },
                      },
                    }}
                    layout="position"
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    data-disabled={!vpcIsLoading && !isLoading ? false : true}
                  >
                    <Radio
                      autoFocus
                      variant="buttonBox"
                      value={`${item.name}-${currentRegion.toLowerCase()}`}
                      isDisabled={!vpcIsLoading && !isLoading ? false : true}
                    >
                      <Skeleton
                        isLoaded={!vpcIsLoading && !isLoading}
                        skeletonHeight="15px"
                        containerHeight={idx === 0 ? "26px" : "24px"}
                        fadeDuration={0}
                      >
                        <Flex alignItems="center">
                          {item.default && (
                            <Badge
                              variant="outline"
                              mx={1}
                              mr={3}
                              py={1}
                              px={3}
                              bg="white"
                            >
                              Default
                            </Badge>
                          )}
                          <Text fontWeight="600" sx={{ display: "inline" }}>
                            {item.name === "default"
                              ? `${item.name}-${currentRegion.toLowerCase()}`
                              : item.name}
                          </Text>
                          <Spacer />
                          <Small variant="muted">
                            {getResources(options.regions, currentRegion)}
                          </Small>
                        </Flex>
                      </Skeleton>
                    </Radio>
                  </MotionContainer>
                ))}
              </AnimatePresence>
            </RadioGroup>
          </MotionBox>
        ) : (
          <MotionBox key="vpc-default" layout="position" mt={6}>
            <Flex alignItems="center" mb={3}>
              {vpcIsLoading && !isLoading && (
                <Spinner color="gray.light" size="md" mr={4} />
              )}
              <Skeleton
                isLoaded={!vpcIsLoading && !isLoading}
                skeletonHeight="15px"
                containerHeight="24px"
                fadeDuration={0}
              >
                <Text fontSize="md" sx={{ display: "inline" }}>
                  VPC network -{" "}
                </Text>
                <Text fontWeight="600" sx={{ display: "inline" }}>
                  {options.vpcs[0].name}-{currentRegion.toLowerCase()}
                </Text>
              </Skeleton>
            </Flex>
          </MotionBox>
        )}
        <MotionBox layout="position">
          <Small w={300}>
            All resources created in this datacenter will be members of the same
            <Link fontWeight="600" href="" color="blue.dark" isExternal>
              {" "}
              VPC network <ExternalLinkIcon ml="1px" mr={1} mb={1} />
            </Link>
            . They can communicate securely over their Private IP addresses.
          </Small>
        </MotionBox>
      </AnimatePresence>
    </MotionBox>
  );
};

export default VpcSelector;
