import React, { useState, useEffect, useRef } from "react";

import {
  Flex,
  Radio,
  RadioGroup,
  FormLabel,
  Spacer,
  Text,
  Container,
  Portal,
} from "@chakra-ui/react";

import { FlagIcon, AlertIcon, ArrowIcon } from "./../icons";
import { Small, InlineText } from "./../typography";
import {
  MotionBox,
  MotionContainer,
  staggerList,
  MotionFlex,
} from "./../animated";
import { Skeleton } from "./../modifiedDropdown/skeleton";
import { AnimatePresence } from "framer-motion";
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  MenuList,
} from "./../modifiedDropdown/menu";
interface Region {
  location: string;
  datacenter: number;
  value: string;
  country: string;
  resources?: number;
}
// FOCUS NOT RELEASING WHEN ANOTHER OPTION IS SELECTED
// Label needs tooltip
// Resources needs tooltip
// Split overflow into groups
// Add a use case to show selector only

const RegionSelectorAlt = (props: any) => {
  const {
    regions,
    setValue,
    getValues,
    isLoading,
    field,
    register,
    ...addtionalProps
  } = props;

  const [overflowRegions, setOverflow] = useState(
    regions.filter((region: Region) => region.hasOwnProperty("resources")),
  );
  const [suggestedRegions, setSuggested] = useState([
    {
      id: 1,
      location: "New York",
      datacenter: 1,
      value: "NYC1",
      country: "USA",
      nearest: true,
    },
  ]);

  useEffect(() => {
    const active = regions.filter((region: Region) => region.resources);
    if (isLoading) {
      // Show three nearest and remove them from the overflow
      setSuggested([
        {
          id: 1,
          location: "New York",
          datacenter: 1,
          value: "NYC1",
          country: "USA",
          nearest: true,
        },
      ]);
    } else if (active.length !== 0) {
      // Sort highest to lowest resource
      // active.sort((a: any, b: any) => {
      //   return b.resources - a.resources;
      // });
      setSuggested(active);
      setOverflow(
        regions.filter((region: Region) => !region.hasOwnProperty("resources")),
      );
    } else {
      // Show three nearest and remove them from the overflow
      setSuggested(regions.filter((region: any) => region.nearest));
      setOverflow([...regions].splice(3));
    }

    setOverflowRegion({
      location: "",
      datacenter: 0,
      value: "",
      country: "",
      resources: 0,
    });
  }, [regions, isLoading]);

  let [overflowRegion, setOverflowRegion] = useState<Region>({
    location: "",
    datacenter: 0,
    value: "",
    country: "",
    resources: 0,
  });

  const regionRef = useRef<any>(null);
  const menuButtonRef = useRef<any>(null);
  const { ref, ...rest } = register("region");

  // NEED TO SET FOCUS TO RADIO NO MENU AFTER SELECT
  // SHOULD BE HANDLE BY ON CHANGE ON THE MENU
  //

  return (
    <MotionBox
      key="region"
      variants={staggerList}
      layout="position"
      // mb={14}
      {...addtionalProps}
    >
      <MotionBox layout="position">
        <FormLabel>Select a datacenter region</FormLabel>
      </MotionBox>
      <RadioGroup {...field}>
        <AnimatePresence>
          {suggestedRegions.map((item: any, idx: number) => (
            <MotionContainer
              key={item.value}
              variants={{
                hidden: {
                  opacity: 0,
                  y: 0,
                  transition: {
                    duration: 0.1 * idx,
                    ease: "easeInOut",
                  },
                },
                show: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.4 * idx,
                    ease: "easeInOut",
                  },
                },
              }}
              layoutId={item.value}
              initial="hidden"
              animate="show"
              exit="hidden"
              variant="buttonBox"
              data-disabled={isLoading || item.disabled ? true : false}
              data-unavailable={item.disabled ? true : false}
              className={
                getValues("region") === item.value && !isLoading
                  ? "checked"
                  : ""
              }
              // initial={{ opacity: 0 }}
              // animate={{ opacity: 1, transition: { duration: 0.2 * idx } }}
              // exit={{ opacity: 0, transition: { duration: 0.2 * idx } }} // Doesn't work framer bug
              onKeyDown={(event) => {
                console.log(overflowRegion);
                if (
                  idx + 1 === suggestedRegions.length &&
                  event.key === "ArrowDown" &&
                  !overflowRegion.value
                ) {
                  event.preventDefault();
                  menuButtonRef.current.click();
                }
              }}
            >
              <Radio
                {...rest}
                variant="buttonBox"
                value={item.value}
                isDisabled={isLoading || item.disabled ? true : false}
              >
                <Skeleton
                  isLoaded={!isLoading}
                  fadeDuration={0}
                  skeletonHeight="15px"
                  containerHeight="24px"
                >
                  <Flex alignItems="center">
                    <FlagIcon
                      country={item.country}
                      w={8}
                      h="1.063rem"
                      mr={3}
                    />
                    {item.disabled && (
                      <AlertIcon alert="warning" h={7} w={7} mr={2} />
                    )}

                    <Text fontWeight="600" sx={{ display: "inline" }}>
                      {item.location}
                      <InlineText fontWeight="400">
                        &nbsp;- Datacenter {item.datacenter}
                      </InlineText>
                      {item.disabled && (
                        <Small mt={1} sx={{ display: "block" }}>
                          This datacenter region currently disabled
                        </Small>
                      )}
                    </Text>

                    <Spacer />
                    {typeof item.resources !== "undefined" && (
                      <Small>{item.resources} resources</Small>
                    )}
                  </Flex>
                </Skeleton>
              </Radio>
            </MotionContainer>
          ))}
        </AnimatePresence>
        <MotionFlex layout="position">
          <Container
            variant="buttonBox_split"
            data-disabled={!isLoading ? false : true}
            mb="0"
            className={
              getValues("region") === overflowRegion.value && !isLoading
                ? "checked"
                : ""
            }
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (!overflowRegion.value) {
                event.preventDefault();
                menuButtonRef.current.click();
              }
            }}
          >
            <Radio
              variant="buttonBox"
              value={overflowRegion.value}
              ref={regionRef}
              isDisabled={isLoading ? true : false}
            >
              <Skeleton
                isLoaded={!isLoading}
                skeletonHeight="15px"
                containerHeight="24px"
                fadeDuration={0}
              >
                <Flex alignItems="center">
                  {overflowRegion.value ? (
                    <Flex alignItems="center">
                      <FlagIcon
                        country={overflowRegion.country}
                        w={8}
                        h="1.063rem"
                        mr={3}
                      />
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        {overflowRegion.location}
                      </Text>
                      <Text fontWeight="400" sx={{ display: "inline" }}>
                        &nbsp;- Datacenter {overflowRegion.datacenter}
                      </Text>
                    </Flex>
                  ) : (
                    <Text fontWeight="600">Select addtional region</Text>
                  )}
                  <Spacer />
                </Flex>
              </Skeleton>
            </Radio>
            <Spacer />
          </Container>
          <Menu placement="bottom-end">
            <MenuButton
              px={4}
              py={2}
              ref={menuButtonRef}
              borderRadius="0 6px 6px 0"
            >
              <ArrowIcon direction="down" w={6} h={6} />
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuOptionGroup
                  {...field}
                  value={overflowRegion.value}
                  onChange={(value: any) => {
                    const idx = overflowRegions.findIndex(
                      (item: any) => item.value === value,
                    );
                    setOverflowRegion(overflowRegions[idx]);
                    setValue("region", value);
                    regionRef?.current.focus();
                  }}
                  type="radio"
                >
                  {overflowRegions.map((item: any) => (
                    <MenuItemOption
                      key={item.value}
                      value={item.value}
                      label={item.location}
                      isDisabled={item.disabled}
                    >
                      <FlagIcon
                        country={item.country}
                        w={8}
                        h="1.063rem"
                        mr={3}
                      />
                      <Text fontWeight="600" sx={{ display: "inline" }}>
                        {item.location}{" "}
                      </Text>
                      <Text
                        fontWeight="400"
                        sx={{
                          display: "inline",
                        }}
                      >
                        - Datacenter {item.datacenter}
                      </Text>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          </Menu>
        </MotionFlex>
      </RadioGroup>
    </MotionBox>
  );
};

export default RegionSelectorAlt;
