import React, { useState, useEffect } from "react";
import { useForm, Controller, useFormState } from "react-hook-form";

import {
  ChakraProvider,
  Box,
  Heading,
  Flex,
  Button,
  Image,
  List,
  ListItem,
  Text,
  ListIcon,
  Link,
} from "@chakra-ui/react";
import theme from "./theme";

import Fonts from "./components/fonts";
import {
  animatedContainer,
  MotionBox,
  staggerList,
} from "./components/animated";
import RegionSelectorAlt from "./components/composite/regionSelectorAlt";
import VpcSelector from "./components/composite/vpcSelector";
import EngineSelector from "./components/composite/engineSelector";
// import ConfigurationSelector from "./components/composite/configurationSelector";
import ConfigurationSelectorUseCase from "./components/composite/configurationSelector-usecase";
import { NavBar, defaultCreateOptions } from "./components/composite/navbar";
import FinalizeAndCreate from "./components/composite/finalizeandcreate";
import { AnimateSharedLayout } from "framer-motion";
import createIllustration from "./assets/dbaas_create_illo_alt.png";
import { SystemIcon } from "./components/icons";
// Old region selector needs to be updated to use menu
// Add migration module - Large (Should replace config expect for standby)
// --- Need migration form
// --- Need migration check
// ------ Should always fail and show some errors - retry works though
// Standby callout checkbox needs to be independent
// --- Needs one or two node option when advanced is open

// Theme selector
// --- Walrus
// --- Dark mode
// --- New theme

export const App = () => {
  const defaultValues = {
    region: "NYC1",
    vpc: "default-nyc1",
    engine: "mongodb-4",
    config: "s-1vcpu-512mb",
    resourceName: "",
    project: "Sofishticated",
  };

  const { control, watch, handleSubmit, setValue, getValues, register } =
    useForm({
      defaultValues,
    });
  const [isLoading, setLoading] = useState(true);
  const [optionsForCreate, setCreateOptions] = useState(defaultCreateOptions);
  const [loadingSpeed, setloadingSpeed] = useState(1000);
  const { dirtyFields } = useFormState({
    control,
  });
  const formUpdates = watch(["region", "engine", "config"]);

  const handleCreateChange = (newValue: any) => {
    setCreateOptions(newValue);
  };

  const handeLoadingChange = (newValue: number) => {
    setloadingSpeed(newValue);
  };

  const onSubmit = (data: any) => console.log(data);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, loadingSpeed);
    return () => clearTimeout(timer);
  }, [loadingSpeed]);

  useEffect(() => {
    setValue("region", optionsForCreate.regions[0].value);
    setValue("vpc", `${optionsForCreate.vpcs[0].name}-nyc1`);
  }, [optionsForCreate, setValue]);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <NavBar
        getValues={getValues}
        createOptions={optionsForCreate}
        onChange={handleCreateChange}
        setLoading={handeLoadingChange}
        isLoading={isLoading}
      />
      <Box>
        <Flex>
          <Box w="600px" m="10" ml="20">
            <Heading variant="h1" mb="10">
              Create a Database Cluster
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AnimateSharedLayout>
                <MotionBox
                  key="form"
                  variants={animatedContainer}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  layout="position"
                >
                  <Controller
                    key="region"
                    name="region"
                    control={control}
                    render={({ field }) => (
                      <RegionSelectorAlt
                        setValue={setValue}
                        getValues={getValues}
                        isLoading={isLoading}
                        field={field}
                        register={register}
                        regions={optionsForCreate.regions}
                      />
                    )}
                  />
                  <Controller
                    key="vpc"
                    name="vpc"
                    control={control}
                    render={({ field }) => (
                      <VpcSelector
                        setValue={setValue}
                        getValues={getValues}
                        isLoading={isLoading}
                        field={field}
                        options={optionsForCreate}
                      />
                    )}
                  />
                  <Controller
                    key="engine"
                    name="engine"
                    control={control}
                    render={({ field }) => (
                      <EngineSelector
                        setValue={setValue}
                        getValues={getValues}
                        isLoading={isLoading}
                        field={field}
                        engines={optionsForCreate.engines}
                      />
                    )}
                  />
                  <Controller
                    key="config"
                    name="config"
                    control={control}
                    render={({ field }) => (
                      <ConfigurationSelectorUseCase
                        setValue={setValue}
                        getValues={getValues}
                        isLoading={isLoading}
                        field={field}
                        configs={optionsForCreate.configuration}
                      />
                    )}
                  />
                  <FinalizeAndCreate
                    Controller={Controller}
                    control={control}
                    setValue={setValue}
                    dirtyFields={dirtyFields}
                    getValues={getValues}
                    formUpdates={formUpdates}
                    options={optionsForCreate}
                    isLoading={isLoading}
                  />
                  <MotionBox layout="position" variants={staggerList} mb={6}>
                    <Button variant="create" type="submit">
                      Create Database Cluster
                    </Button>
                  </MotionBox>
                </MotionBox>
              </AnimateSharedLayout>
            </form>
          </Box>
          <Box w="400px" mx="20" mt="24">
            <Image src={createIllustration} mb="8" width="350px"></Image>
            <Heading variant="h3" fontWeight="600" mb="4">
              Worry-free Managed Database
            </Heading>
            <Text color="gray.dark" mb="8">
              Leave the complexity of database administration to us. So you can
              focus on building great apps.
            </Text>
            <List spacing={3} mb="8">
              <ListItem>
                <ListIcon
                  as={SystemIcon}
                  type="checkmark"
                  stroke="green.medium"
                  verticalAlign="middle"
                  w="7"
                  h="7"
                />
                <Text fontSize="h4" sx={{ display: "inline" }}>
                  Point in time recovery (PITR)
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={SystemIcon}
                  type="checkmark"
                  stroke="green.medium"
                  verticalAlign="middle"
                  w="7"
                  h="7"
                />
                <Text fontSize="h4" sx={{ display: "inline" }}>
                  Automated Failover and Failback
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={SystemIcon}
                  type="checkmark"
                  stroke="green.medium"
                  verticalAlign="middle"
                  w="7"
                  h="7"
                />
                <Text fontSize="h4" sx={{ display: "inline" }}>
                  Logging &amp; Metrics Dashboard
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={SystemIcon}
                  type="checkmark"
                  stroke="green.medium"
                  verticalAlign="middle"
                  w="7"
                  h="7"
                />
                <Text fontSize="h4" sx={{ display: "inline" }}>
                  Zero-Downtime Scaling and Forking
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon
                  as={SystemIcon}
                  type="checkmark"
                  stroke="green.medium"
                  verticalAlign="middle"
                  w="7"
                  h="7"
                />
                <Text fontSize="h4" sx={{ display: "inline" }}>
                  Automated maintenance
                </Text>
              </ListItem>
            </List>
            <Link
              color="blue.dark"
              fontWeight="600"
              href="https://docs.digitalocean.com/products/databases/"
              isExternal
            >
              Check out all Managed Database has to offer
              <SystemIcon w="5" h="5" type="sidebar" ml="1"></SystemIcon>
            </Link>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};
