import { chakra, useStyleConfig } from "@chakra-ui/react";
import React from "react";

export const SmallDiv = chakra("small", {});
export const Span = chakra("span", {});

export const Small = (props: any) => {
  const { size, variant, children, ...rest } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const animate = opened ? "enter" : "exit";
  const styles = useStyleConfig("Small", { size, variant });
  return (
    <SmallDiv __css={styles} {...rest}>
      {children}
    </SmallDiv>
  );
};

export const InlineText = (props: any) => {
  const { size, variant, children, ...rest } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const animate = opened ? "enter" : "exit";
  const styles = useStyleConfig("InlineText", { size, variant });
  return (
    <Span __css={styles} {...rest}>
      {children}
    </Span>
  );
};
